<template>
  <v-card>
    <v-toolbar dense dark color="primary">
      <v-toolbar-title><h4 class="font-weight-light">MONTHLY COLLECTION REPORTS</h4>
      </v-toolbar-title>
    </v-toolbar>
    <v-flex xs12 class="mx-2 pt-6">
      <v-row>
        <v-col
          md="12"
          cols="12"
        ></v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-select
            v-model="month_of"
            class="mx-3"
            :items="month_of_items"
            item-value="id"
            item-text="month_of"
            label="Month Of"
            required
            @change="monthly_collection_data"
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-layout row wrap class="align-center">
        <v-flex xs12 md3 v-if="branch_id>1">
          <v-select
            class="mx-3 pt-5"
            :items="collectors_items"
            item-value="id"
            item-text="full_name"
            label="Collector"
            required
            @change="monthly_collection_data"
            v-model="collector_id"
            dense
          ></v-select>
        </v-flex>
        <v-flex xs12 md3 v-if="branch_id>1">
          <v-select
            v-model="category_se"
            class="mx-3 pt-5"
            :items="type_of_loan_items"
            item-value="type"
            item-text="type"
            label="Type of Loan"
            required
            @change="monthly_collection_data"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3 v-if="branch_id>1">
          <v-select
            v-model="sorted_by"
            class="mx-3 pt-5"
            :items="['Due Date','Name']"
            label="Sorted By"
            required
            @change="monthly_collection_data"
          ></v-select>
        </v-flex>
        <v-flex xs12 md4>
                        <span class="headline mx-3 pt-5">
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_data(0)"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
          <span class="headline mx-3 pt-5">
                                                Print Unpaid Only
                                                <v-icon
                                                  class="mr-2"
                                                  color="error"
                                                  @click="print_data(1)"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
          <span class="headline mx-3 pt-5">
                                                Print Paid Only
                                                <v-icon
                                                  class="mr-2"
                                                  color="info"
                                                  @click="print_data(2)"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-row>
      <v-col
        md="12"
        cols="12"
      >

      </v-col>

      <!--      <v-col-->
      <!--        md="3"-->
      <!--        cols="12"-->
      <!--      >-->
      <!--        <v-select-->
      <!--          v-model="specify_cat"-->
      <!--          class="mx-3"-->
      <!--          :items="specify_cat_items"-->
      <!--          item-value="specify_type_of_loan"-->
      <!--          item-text="specify_type_of_loan"-->
      <!--          label="Specify"-->
      <!--          required-->
      <!--          @change="monthly_collection_data"-->
      <!--          dense-->
      <!--        ></v-select>-->
      <!--      </v-col>-->
    </v-row>
    <v-card-text>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-uppercase">
              Total Premium
            </th>
            <th class="text-uppercase">
              Total Balance Pricipal
            </th>
            <th class="text-uppercase">
              Total Balance
            </th>
            <th class=" text-uppercase">
              Total Quota
            </th>
            <th class="text-center text-uppercase">
              Total Collection
            </th>
            <th class="text-center text-uppercase">
              Total Due Account
            </th>
            <th class="text-center text-uppercase">
              Total Due Collected
            </th>
            <th class="text-center text-uppercase">
              TCP
            </th>
            <th class="text-center text-uppercase">
              DAP
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in data2"
            :key="item.id"
          >
            <td class="text-center">{{ item.tot_amount_due }}</td>
            <td class="text-center">{{ item.total_balance_principal }}</td>
            <td class="text-center">{{ item.total_balance }}</td>
            <td class="text-center">
              {{ item.total_quota }}
            </td>
            <td class="text-center">
              {{ item.total_collection }}
            </td>
            <td class="text-center">
              {{ item.total_due_collected }}
            </td>
            <td class="text-center">
              {{ item.total_due_collection }}
            </td>
            <td class="text-center">
              {{ item.tcp+' %' }}
            </td>
            <td class="text-center">
              {{ item.dap +' %'}}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-text>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-uppercase">
              Type
            </th>
            <th class=" text-uppercase">
              App. #
            </th>
            <th class="text-center text-uppercase">
              Name
            </th>
            <th class="text-center text-uppercase">
              Address
            </th>
            <th class="text-center text-uppercase">
              Terms
            </th>
            <th class="text-center text-uppercase" v-if="position=== 'ADMIN'">
              Balance Pricipal
            </th>
            <th class="text-center text-uppercase">
              Balance
            </th>
            <th class="text-center text-uppercase">
              Premium
            </th>
            <th class="text-center text-uppercase">
              Aging
            </th>
            <th class="text-center text-uppercase">
              Due Date
            </th>
            <th class="text-center text-uppercase">
              Penalty
            </th>
            <th class="text-center text-uppercase">
              Weekly
            </th>
            <th class="text-center text-uppercase">
              15/30
            </th>
            <th class="text-center text-uppercase">
              Monthly
            </th>
            <th class="text-center text-uppercase">
              Payments
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in data"
            :key="item.id"
          >
            <td>{{ item.type_of_loan }}</td>
            <td>
              {{ item.application_no }}
            </td>
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ item.present_address }}
            </td>
            <td>
              {{ item.terms }}
            </td>
            <td v-if="position=== 'ADMIN'">
              {{ formatPrice(item.principal_balance) }}
            </td>
            <td>
              {{ item.currency_balance }}
            </td>
            <td>
              {{ item.amount_due }}
            </td>
            <td>
              {{ item.aging }}
            </td>
            <td>
              {{ item.due_date }}
            </td>
            <td>
              {{ item.currency_penalty }}
            </td>
            <td>
              {{ item.currency_weekly }}
            </td>
            <td>
              {{ item.currency_halfMonth }}
            </td>
            <td>
              {{ item.currency_monthly }}
            </td>
            <td>
              {{ item.currency_payments }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";
  import moment from "moment";
  import {mdiAccountSearchOutline, mdiPrinter} from "@mdi/js";

  export default {
    setup() {
      return {
        icons: {
          mdiAccountSearchOutline,
          mdiPrinter,
        },
      }
    },
    data() {
      return {
        data: [],
        data2: [],
        month_of_items: [],
        month_of: '',
        category: 'With Payment',
        category_se: '',
        collectors_items: [],
        collector_id: 0,
        type_of_loan_items: [],
        specify_cat: '',
        sorted_by: '',
        specify_cat_items: [],
      }
    },
    mounted() {
      this.initialize_data()
    },
    watch: {
      branch_id: function () {
        this.initialize_data()
      },
    },
    computed: {
      ...mapGetters('system_data', ['company_logo']),
      ...mapGetters('authentication', ['branch_id', 'branch', 'branch_address', 'branch_contact_no', 'position']),
    },
    methods: {
      ...mapActions('monthly_collection_reports', ['monthly_collection_report', 'previous_monthly_collection_report']),
      ...mapActions('transaction_months', ['retrieve_transaction_month']),
      ...mapActions('staff', ['staff_have_mcr', 'staff_have_prev_mcr']),
      ...mapActions('loans_data', ['type_of_loan_list']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      initialize_data() {
        this.type_of_loan_list({
          type: 'All'
        })
          .then(response => {
            this.type_of_loan_items = response.data
            this.category_se = 'All'
          })
        const data3 = new FormData()
        data3.append('branch_id', this.branch_id);
        this.retrieve_transaction_month(data3)
          .then(response => {
            this.month_of_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
        this.data = []
        this.month_of = ''
        this.category = ''
      },
      async monthly_collection_data() {
        this.data2 = []
        var index = this.month_of_items.map(function (x) {
          return x.id;
        }).indexOf(this.month_of)
        if (index != -1) {
          const data2 = new FormData()
          data2.append('month_of', this.month_of_items[index].month_of);
          data2.append('branch_id', this.branch_id);
          if (index === 0) {
            await this.staff_have_mcr(data2)
              .then(response => {
                this.collectors_items = response.data
                this.collectors_items.splice(0, 0, {
                  id: 0,
                  full_name: 'All',
                })
              })
              .catch(error => {
                console.log(error)
              })
          } else {
            await this.staff_have_prev_mcr(data2)
              .then(response => {
                this.collectors_items = response.data
                this.collectors_items.splice(0, 0, {
                  id: 0,
                  full_name: 'All',
                })
              })
              .catch(error => {
                console.log(error)
              })
          }
          const data = new FormData()
          data.append('branch_id', this.branch_id);
          data.append('month_of', this.month_of_items[index].month_of);
          data.append('month_start', this.month_of_items[index].month_start);
          data.append('is_new_month', 0);
          data.append('collector_id', this.collector_id);
          data.append('type_of_loan', this.category_se);
          data.append('specify_type_of_loan', this.specify_cat);
          data.append('sorted_by', this.sorted_by);
          if (index === 0) {
            await this.monthly_collection_report(data)
              .then(response => {
                this.data = response.data
                if (this.specify_cat_items.length === 0) {
                  this.specify_cat_items = this.data
                }
              })
              .catch(error => {
                console.log(error)
              })
          } else {
            await this.previous_monthly_collection_report(data)
              .then(response => {
                this.data = response.data
                if (this.specify_cat_items.length === 0) {
                  this.specify_cat_items = this.data
                }
              })
              .catch(error => {
                console.log(error)
              })
          }
          var tot_balance = 0
          var tot_amount_due = 0
          var total_balance_principal = 0
          var tot_week = 0
          var tot_half = 0
          var tot_month = 0
          var tot_due_account = 0
          var tot_due_collected = 0
          var tot_advance_account = 0
          var tot_advance_collected = 0
          var tot_collected_amount = 0
          await this.data.forEach(function (item, index, payment) {
            total_balance_principal += parseFloat(item.principal_balance)
            tot_balance += parseFloat(item.raw_balance)
            tot_amount_due += parseFloat(item.amount_due_raw)
            tot_week += parseFloat(item.weekly)
            tot_half += parseFloat(item.halfMonth)
            tot_month += parseFloat(item.monthly)
            tot_due_collected += parseFloat(item.due_collected)
            tot_due_account += parseFloat(item.due_account)
            tot_advance_account += parseFloat(item.advance_account)
            tot_advance_collected += parseFloat(item.advance_account_collect)
            tot_collected_amount += parseFloat(item.payments)
          })
          var quotaaaa = parseFloat(tot_week) + parseFloat(tot_half) + parseFloat(tot_month);
          this.data2.push({
            tot_amount_due: (tot_amount_due / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            total_balance_principal: (total_balance_principal / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            total_balance: (tot_balance / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            total_quota: (quotaaaa / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            total_collection: (tot_collected_amount / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            total_due_collected: (tot_due_account / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            total_due_collection: (tot_due_collected / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            total_advance_collected: (tot_advance_account / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            total_advance_collection: (tot_advance_collected / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            tcp: ((tot_collected_amount / quotaaaa) * 100).toFixed(2),
            dap: ((tot_due_collected / tot_due_account) * 100).toFixed(2),
          })
        }
      },
      print_data(is_unpaid) {
        var imgData = this.company_logo
        var array = []
        var array2 = []
        if (this.position=== 'ADMIN') {
          array.push(
            [
              {text: '#', alignment: 'center', style: 'label'},
              {text: 'TYPE', alignment: 'center', style: 'label'},
              {text: 'APP. #', alignment: 'center', style: 'label'},
              {text: 'NAME', alignment: 'center', style: 'label'},
              {text: 'ADDRESS', alignment: 'center', style: 'label'},
              {text: 'TERMS', alignment: 'center', style: 'label'},
              {text: 'BALANCE PRINCIPAL', alignment: 'center', style: 'label'},
              {text: 'BALANCE', alignment: 'center', style: 'label'},
              {text: 'PREMIUM', alignment: 'center', style: 'label'},
              {text: 'AGING', alignment: 'center', style: 'label'},
              {text: 'DUE DATE', alignment: 'center', style: 'label'},
              {text: 'PENALTY', alignment: 'center', style: 'label'},
              {text: 'WEEKLY', alignment: 'center', style: 'label'},
              {text: '15/30', alignment: 'center', style: 'label'},
              {text: 'MONTHLY', alignment: 'center', style: 'label'},
              {text: 'PAYMENTS', alignment: 'center', style: 'label'},
            ]
          )
        } else {
          array.push(
            [
              {text: '#', alignment: 'center', style: 'label'},
              {text: 'TYPE', alignment: 'center', style: 'label'},
              {text: 'APP. #', alignment: 'center', style: 'label'},
              {text: 'NAME', alignment: 'center', style: 'label'},
              {text: 'ADDRESS', alignment: 'center', style: 'label'},
              {text: 'TERMS', alignment: 'center', style: 'label'},
              {text: 'BALANCE', alignment: 'center', style: 'label'},
              {text: 'PREMIUM', alignment: 'center', style: 'label'},
              {text: 'AGING', alignment: 'center', style: 'label'},
              {text: 'DUE DATE', alignment: 'center', style: 'label'},
              {text: 'PENALTY', alignment: 'center', style: 'label'},
              {text: 'WEEKLY', alignment: 'center', style: 'label'},
              {text: '15/30', alignment: 'center', style: 'label'},
              {text: 'MONTHLY', alignment: 'center', style: 'label'},
              {text: 'PAYMENTS', alignment: 'center', style: 'label'},
            ]
          )
        }

        array2.push(
          [
            {text: 'Total Premium', alignment: 'center', style: 'label'},
            {text: 'Total Balance Pricipal', alignment: 'center', style: 'label'},
            {text: 'Total Balance', alignment: 'center', style: 'label'},
            {text: 'Total Quota', alignment: 'center', style: 'label'},
            {text: 'Total Collection', alignment: 'center', style: 'label'},
            {text: 'Total Due Account', alignment: 'center', style: 'label'},
            {text: 'Total Due Collected', alignment: 'center', style: 'label'},
            {text: 'TCP', alignment: 'center', style: 'label'},
            {text: 'DAP', alignment: 'center', style: 'label'},
          ]
        )
        if (this.data.length > 0) {
          var tot_balance = 0
          var tot_amount_due = 0
          var tot_week = 0
          var tot_half = 0
          var tot_month = 0
          var tot_due_account = 0
          var tot_due_collected = 0
          var tot_advance_account = 0
          var tot_advance_collected = 0
          var tot_collected_amount = 0
          var counter = 0
          var is_admin=this.position=== 'ADMIN'
          this.data.forEach(function (item, index, payment) {

            tot_balance += parseFloat(item.raw_balance)
            tot_amount_due += parseFloat(item.amount_due_raw)
            tot_week += parseFloat(item.weekly)
            tot_half += parseFloat(item.halfMonth)
            tot_month += parseFloat(item.monthly)
            tot_due_collected += parseFloat(item.due_collected)
            tot_due_account += parseFloat(item.due_account)
            tot_advance_account += parseFloat(item.advance_account)
            tot_advance_collected += parseFloat(item.advance_collected)
            tot_collected_amount += parseFloat(item.payments)
            var proveed = true;
            if (is_unpaid === 1) {
              if (parseFloat(item.payments) > 0) {
                proveed = false;
              }
            } else if (is_unpaid === 2) {
              if (parseFloat(item.payments) === 0) {
                proveed = false;
              }
            }
            if (proveed) {
              counter++
              if (is_admin) {
                array.push(
                  [
                    {text: counter, alignment: 'center'},
                    {text: item.type_of_loan, alignment: 'center'},
                    {text: item.application_no, alignment: 'left'},
                    {text: item.name, alignment: 'left'},
                    {text: item.present_address, alignment: 'left'},
                    {
                      text: item.terms,
                      alignment: 'left'
                    },
                    {
                      text: (item.principal_balance / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                      alignment: 'center'
                    },
                    {text: item.currency_balance, alignment: 'center'},
                    {text: item.amount_due, alignment: 'center'},
                    {text: item.aging, alignment: 'center'},
                    {text: item.due_date, alignment: 'center'},
                    {text: item.currency_penalty, alignment: 'center'},
                    {text: item.currency_weekly, alignment: 'center'},
                    {text: item.currency_halfMonth, alignment: 'center'},
                    {text: item.currency_monthly, alignment: 'center'},
                    {text: item.currency_payments, alignment: 'center'},
                  ]
                )
              } else {

                array.push(
                  [
                    {text: counter, alignment: 'center'},
                    {text: item.type_of_loan, alignment: 'center'},
                    {text: item.application_no, alignment: 'left'},
                    {text: item.name, alignment: 'left'},
                    {text: item.present_address, alignment: 'left'},
                    {
                      text: item.terms,
                      alignment: 'left'
                    },
                    {text: item.currency_balance, alignment: 'center'},
                    {text: item.amount_due, alignment: 'center'},
                    {text: item.aging, alignment: 'center'},
                    {text: item.due_date, alignment: 'center'},
                    {text: item.currency_penalty, alignment: 'center'},
                    {text: item.currency_weekly, alignment: 'center'},
                    {text: item.currency_halfMonth, alignment: 'center'},
                    {text: item.currency_monthly, alignment: 'center'},
                    {text: item.currency_payments, alignment: 'center'},
                  ]
                )
              }
            }
          })
          this.data2.forEach(function (item, index, payment) {
            array2.push(
              [
                {text: item.tot_amount_due, alignment: 'center'},
                {text: item.total_balance_principal, alignment: 'center'},
                {text: item.total_balance, alignment: 'center'},
                {text: item.total_quota, alignment: 'center'},
                {text: item.total_collection, alignment: 'center'},
                {text: item.total_due_collected, alignment: 'center'},
                {text: item.total_due_collection, alignment: 'center'},
                {text: item.total_due_collection, alignment: 'center'},
                {text: item.tcp + ' %', alignment: 'center'},
                {text: item.dap + ' %', alignment: 'center'},
              ]
            )
          })
        } else {
          array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: {
            width: 612,
            height: 936,
          },
          pageOrientation: 'landscape',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {
              text: 'MONTHLY COLLECTION REPORTS' + (is_unpaid ? ' ( UNPAID ONLY )' : ''),
              style: 'title'
            },
            '============================================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            {
              text: 'Month Of: ' + this.month_of_items[this.month_of_items.map(function (x) {
                return x.id;
              }).indexOf(this.month_of)].month_of, style: 'main_info'
            },
            {text: 'Category: ' + this.category_se, style: 'main_info'},
            {
              text: 'Collector Name: ' + this.collectors_items[this.collectors_items.map(function (x) {
                return x.id;
              }).indexOf(this.collector_id)].full_name, style: 'main_info'
            },

            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [80, 80, 80, 80, 80, 80, 80, 50, 30, 40, 40, 40, 40,],
                body: array2,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [20, 50, 45, 85, 120, 25, 38, 38, 38, 38, 50, 30, 38, 38, 38, 38,],
                body: array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },
          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 7,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    },
  }
</script>
